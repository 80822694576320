import React from 'react';

//home page
const Home = React.lazy(() => import('./pages/Home'));

//
// Teachers pages
const TeacherArea = React.lazy(() => import('./pages/teacherArea/TeacherArea'));
// const TeacherAreaOrdered = React.lazy(() => import('./pages/teacherArea/TeacherAreaOrdered'));

//
// Students pages
const StudentList = React.lazy(() => import('./pages/student/StudentList'));
const StudentAddEdit = React.lazy(() => import('./pages/student/StudentAddEdit'));

//
// Users pages
const UsersList = React.lazy(() => import('./pages/user/UserList'));
const UsersAddEdit = React.lazy(() => import('./pages/user/UserAddEdit'));


// Descontos pages
const DiscountList = React.lazy(() => import('./pages/discount/DiscountList'));
const DiscountAddEdit = React.lazy(() => import('./pages/discount/DiscountAddEdit'));

//
// Class
const ClassList = React.lazy(() => import('./pages/class/ClassList'));
const ClassAddEdit = React.lazy(() => import('./pages/class/ClassAddEdit'));

//
// Courses
const CourseList = React.lazy(() => import('./pages/course/CourseList'));
const CourseAddEdit = React.lazy(() => import('./pages/course/CourseAddEdit'));

//
// Questions
const QuestionList = React.lazy(() => import('./pages/question/QuestionList'));
const QuestionAddEdit = React.lazy(() => import('./pages/question/QuestionAddEdit'));


//
// Afilliate
const AffiliateAddEdit = React.lazy(() => import('./pages/affiliate/AffiliateAddEdit'));
const AffiliateList = React.lazy(() => import('./pages/affiliate/AffiliateList'));

//
// Payments
const PaymentList = React.lazy(() => import('./pages/payment/PaymentList'));

// Profile
const Profile = React.lazy(() => import('./pages/profile/ProfileEdit'));

//
// Dashboard pages
// const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'));

//
// Course Class
const CourseClassAddEdit = React.lazy(() => import('./pages/courseClass/CourseClassAddEdit'));
const CourseClassList = React.lazy(() => import('./pages/courseClass/CourseClassList'));


//
// Prospections
const CRMProspections = React.lazy(() => import('./pages/crm/Salesman/Students'));
const CRMProspectionDetail = React.lazy(() => import('./pages/crm/Prospection'));
const CRMSalesAgents = React.lazy(() => import('./pages/crm/Admin/Students'));
const CRMLeadPanel = React.lazy(() => import('./pages/crm/Panel'));

//
// Gabarito
const GabaritoAdd = React.lazy(() => import('./pages/gabarito/Create'));
const GabaritoEdit = React.lazy(() => import('./pages/gabarito/Edit'));

//
// Leads
const LeadList = React.lazy(() => import('./pages/lead/List'));


//
// Quiz
const QuizQuestionList = React.lazy(() => import('./pages/quiz/question/List'));
const QuizQuestionAdd = React.lazy(() => import('./pages/quiz/question/Form'));
const QuizQuestionEdit = React.lazy(() => import('./pages/quiz/question/Form'));

//
// Scheduled Test
const ScheduledTests = React.lazy(() => import('./pages/scheduledTest/List'));
//
// Notifications
const Notifications = React.lazy(() => import('./pages/notifications/index'));

// ReportGenerator CFP
const ReportGeneratorCFP = React.lazy(() => import('./pages/reportGeneratorCFP/List'));
const ReportGeneratorCFPEdit = React.lazy(() => import('./pages/reportGeneratorCFP/Form'));
const ReportGeneratorCFPCreate = React.lazy(() => import('./pages/reportGeneratorCFP/Form'));
const ReportGeneratorCFPSend = React.lazy(() => import('./pages/reportGeneratorCFP/SendReport'));
const ReportGeneratorCFPEditText = React.lazy(() => import('./pages/reportGeneratorCFP/FormText'));

const routes = [
  { path: '/', exact: true, name: 'Home', component: Home },
  // { path: '/dashboard', name: 'Dashboard', component: Dashboard },

  /* Teachers area */
  { path: '/professor', name: 'Área do Professor', component: TeacherArea },

  /* Students */
  { path: '/aluno/:id', name: 'Adicionar', component: StudentAddEdit },
  { path: '/aluno/adicionar', name: 'Adicionar', component: StudentAddEdit },
  { path: '/aluno', name: 'Alunos', component: StudentList },

  /* Users */
  { path: '/users/add', exact: true, name: 'Adicionar Usuários', component: UsersAddEdit },
  { path: '/users/:id', exact: true, name: 'Editar Usuário', component: UsersAddEdit },
  { path: '/users', name: 'Usuários', component: UsersList },

  // Afiliados
  { path: '/afiliados/add', name: 'Adicionar afiliado', component: AffiliateAddEdit },
  { path: '/afiliados/:id', name: 'Editar afiliado', component: AffiliateAddEdit },
  { path: '/afiliados', name: 'Afiliados', component: AffiliateList },

  // Pagamentos
  { path: '/pagamentos', name: 'Pagamentos', component: PaymentList },

  // Descontos
  { path: '/descontos/add', name: 'Adicionar desconto', component: DiscountAddEdit },
  { path: '/descontos/:id', name: 'Editar descconto', component: DiscountAddEdit },
  { path: '/descontos', name: 'Descontos', component: DiscountList },

  // Questoes
  { path: '/questoes/add', name: 'Adicionar questão', component: QuestionAddEdit },
  { path: '/questoes/:id', name: 'Editar questão', component: QuestionAddEdit },
  { path: '/questoes', name: 'Questoes', component: QuestionList },

  // Aula
  { path: '/aulas/add', name: 'Detalhes da Aula', component: ClassAddEdit },
  { path: '/aulas/:id', name: 'Detalhes da Aula', component: ClassAddEdit },
  { path: '/aulas', name: 'Aulas', component: ClassList },

  // Course
  { path: '/cursos/add', name: 'Adicionar curso', component: CourseAddEdit },
  { path: '/cursos/:id', name: 'Adicionar curso', component: CourseAddEdit },
  { path: '/cursos', name: 'Cursos', component: CourseList },

  /* Dashboard */
  // { path: '/dashboard', name: 'Dashboard', component: Dashboard },

  /* Logged user edit */
  { path: '/profile', name: 'Perfil', component: Profile },

  /* Course Class */
  { path: '/courseclass/add', name: 'Adicionar turma', component: CourseClassAddEdit },
  { path: '/courseclass/:id', name: 'Editar turma', component: CourseClassAddEdit },
  { path: '/courseclass', name: 'Turmas', component: CourseClassList },

  /* CRM */
  { path: '/crm-prospections/:id', name: 'Detalhar prospecção', component: CRMProspectionDetail },
  { path: '/crm-prospections', name: 'Lista de prospecções', component: CRMProspections },
  { path: '/crm-sales-agents', name: 'Lista de prospecções', component: CRMSalesAgents },
  { path: '/crm-leads-panel', name: 'CRM - Painel de Leads Convertidos', component: CRMLeadPanel},


  /* Gabarito */
  { path: '/gabarito-cfp/:id', name: 'Gabarito CFP - Edit', component: GabaritoEdit },
  { path: '/gabarito-cfp', name: 'Gabarito CFP', component: GabaritoAdd },

  /* Leads */
  { path: '/leads', name: 'Leads', component: LeadList },

  /* Quiz - Questions */
  { path: '/quiz-questions/add', name: 'App Quiz - Questões - Adicionar', component: QuizQuestionAdd },
  { path: '/quiz-questions/:id', name: 'App Quiz - Questões - Editar', component: QuizQuestionEdit },
  { path: '/quiz-questions', name: 'App Quiz - Questões', component: QuizQuestionList },

  /* ScheduledTests */
  { path: '/scheduled-tests', name: 'Provas programadas pelos alunos', component: ScheduledTests },

  /* Notifications */
  { path: '/notifications', name: 'Notificações de Push', component: Notifications },

  /* Report Generator CFP */
  { path: '/report-generator-cfp-weekly-edit/:type/:id', name: 'Gerenciar Relatório CFP semanal - Editar mensagem', component: ReportGeneratorCFPEdit },
  { path: '/report-generator-cfp-weekly-add/:type', name: 'Gerenciar Relatório CFP semanal - Adicionar', component: ReportGeneratorCFPCreate },
  { path: '/report-generator-cfp-weekly/:type', name: 'Gerenciar Relatório CFP semanal', component: ReportGeneratorCFP },
  { path: '/report-generator-cfp-weekly-send/:type', name: 'Gerenciar Relatório CFP semanal', component: ReportGeneratorCFPSend },
  { path: '/report-generator-cfp-simulated-edit/:type/:id', name: 'Gerenciar Relatório CFP semanal - Editar mensagem', component: ReportGeneratorCFPEdit },
  { path: '/report-generator-cfp-simulated-add/:type', name: 'Gerenciar Relatório CFP semanal - Adicionar', component: ReportGeneratorCFPCreate },
  { path: '/report-generator-cfp-simulated/:type', name: 'Gerenciar Relatório CFP semanal', component: ReportGeneratorCFP },
  { path: '/report-generator-cfp-simulated-send/:type', name: 'Gerenciar Relatório CFP semanal', component: ReportGeneratorCFPSend },
  { path: '/report-generator-cfp-weekly-edit-text/:type/:typeReportId/:categoryReportId', name: 'Gerenciar Texto Relatório CFP semanal - Editar Texto', component: ReportGeneratorCFPEditText },
  { path: '/report-generator-cfp-simulated-edit-text/:type/:typeReportId/:categoryReportId', name: 'Gerenciar Texto Relatório CFP semanal - Editar Texto', component: ReportGeneratorCFPEditText },
];

export default routes;
